import React from 'react'
import {papers, groupBibEntries} from '../utils/bibliography'

import locales from '../utils/strings'
import styles from '../templates/basic.module.less'
import Layout from "../components/layout";

const entryGroups = groupBibEntries(papers)

const PublicationsPage = ({ location, pathContext }) => {
  const {lang} = pathContext

  if (!locales[lang]) {
    throw new Error(`Unknown language: ${lang}.`)
  }

  const strings = locales[lang].publications

  return <Layout location={location}>
    <div>
      <h1 className={styles.title}>{strings.title}</h1>
      {
        entryGroups.map(([year, subgroups]) => (
          <div key={year}>
            <h2>{strings.yearHeading.replace('{}', year)}</h2>
            {
              subgroups.map(([myType, entries]) => <div key={myType}>
                <h3>{strings.types[myType] || myType}</h3>
                <ul>
                  { entries.map(entry => <PublicationEntry entry={entry} key={entry.id} />) }
                </ul>
              </div>)
            }
          </div>
        ))
      }
    </div>
  </Layout>
}

const PublicationEntry = ({ entry }) => {
  const lang = entry.memo.split('-')[2] // 'e' | 'j'

  const authors = entry.author.split(' and ')
  const sep = lang === 'j' ? '，' : ', '
  let html = lang === 'j' ? authors.join('，') : authors.join(', ')

  html += sep + `<b>${entry.title}</b>`

  if (entry.booktitle) {
    html += sep + (lang === 'j' ? `${entry.booktitle}` : `<i>${entry.booktitle}</i>`)
  }

  if (entry.volume) {
    html += sep + `Vol.${entry.volume}`
  }

  if (entry.number) {
    html += sep + `No.${entry.number}`
  }

  if (entry.pages) {
    html += sep + `pp. ${entry.pages}`
  }

  if (entry.address) {
    html += sep + `${entry.address}`
  }

  const [year, month] = entry.yearMonth
  html += sep + (lang === 'j' ? `${year}年${month}月` : `${entry.month} ${entry.year}`)

  html += '.'

  return <li dangerouslySetInnerHTML={{__html: html}} />
}

export default PublicationsPage
